






























































































































































import { IImportCostCreate } from "@/models/interface-v2/import-cost.interface";
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "ImportCostTable",
  computed: {
    ...mapState({
      form: (st: any) => st.importCostStore.form as IImportCostCreate,
    })
  },
});
