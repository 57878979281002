var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-table",
    {
      attrs: {
        "data-source": _vm.form.importCostLines,
        pagination: {
          showTotal: function(total) {
            return _vm.$t("lbl_total_items", { total: total })
          }
        },
        "row-key": function(r, i) {
          return i
        },
        scroll: { y: 520, x: "calc(1500px + 50%)" }
      }
    },
    [
      _c(
        "a-table-column",
        { key: "no", attrs: { width: "75px", "data-index": "no" } },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_number_short")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "productCode",
          attrs: { "data-index": "productCode" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_product_code")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "productName",
          attrs: { "data-index": "productName" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_product_name")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "qty",
          attrs: { "data-index": "qty" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("toDecimalQty")(record.qty)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_qty_received")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "uomId",
          attrs: { "data-index": "uomId" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [_vm._v(_vm._s(record.uom || "-"))])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_uom")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "price",
          attrs: { "data-index": "price" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(text)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_unit_price")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "totalPrice",
          attrs: { "data-index": "totalPrice" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(text)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_total_price")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "portion",
          attrs: { "data-index": "portion" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(record.portion)) + "%")
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_portion")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "transport",
          attrs: { "data-index": "transport" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(record.transport)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_transport_product")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "total",
          attrs: { "data-index": "total" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(record.total)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_total")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "gijConversion",
          attrs: { "data-index": "gijConversion" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(record.gijConversion)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_gij_convertion")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "localTransportation",
          attrs: { "data-index": "localTransportation" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(record.localTransportation)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_local_transportation")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "importDuty",
          attrs: { "data-index": "importDuty" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(record.importDuty)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_import_duty")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "totalIDR",
          attrs: { "data-index": "totalIDR" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(record.totalIDR)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_total_idr")) + " ")
          ])
        ]
      ),
      _c(
        "a-table-column",
        {
          key: "cogs",
          attrs: { "data-index": "cogs" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(text, record) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(record.cogs)))
                ])
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_cogs")) + " ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }